import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store/store'
import * as getters from '../store/getters/types'
import { routes } from './routes'
import { isExistRole } from '../utils/role'

Vue.use(Router)

const opts = {
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  },
  routes
}

export const router = new Router(opts)

router.beforeEach(function (to, from, next) {
  const {
    [getters.ACCOUNT]: account,
    [getters.VENDOR]: vendor
  } = store.getters

  const { middleware } = to.meta
  const role = account?.role?.code

  if (!isExistRole(role)) {
    const uri = (process.env.NODE_ENV === 'development') ? process.env.VUE_APP_PROXY : location.origin
    const _from = new URLSearchParams(location.search).get('_from') || ''

    location.replace(`${uri}/${_from}`)
    return
  }

  if (middleware) {
    middleware({ role, vendor, next })
    return
  }

  next()
})
