<template>
  <VAlert
    v-if="notification"
    class="notification"
    transition="scale-transition"
    :type="notification.type"
  >
    {{ notification.msg }}

    <template #close>
      <VBtn
        icon
        small
        :aria-label="$t('$vuetify.close')"
        @click="deleteNotification"
      >
        <VIcon>close</VIcon>
      </VBtn>
    </template>
  </VAlert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'UNotification',

  computed: mapGetters({
    notification: getters.NOTIFICATION
  }),

  methods: {
    ...mapActions({
      deleteNotification: actions.DELETE_NOTIFICATION
    })
  }
}
</script>

<style lang="scss">
.v-application {
  .notification {
    position: fixed;
    top: var(--app-bar-height);
    right: 0;
    max-width: 400px;
    min-width: 320px;
    z-index: 999;
  }
}
</style>
