export default class Notification {
  #timer = null
  #duration = 10000

  constructor ({ msg, type }, cb) {
    this.msg = msg
    this.type = type || 'primary'

    this.#timer = setTimeout(cb, this.#duration)
  }

  destroy () {
    if (this.#timer) {
      clearTimeout(this.#timer)
      this.#timer = null
    }
  }
}
