<template>
  <VApp
    :class="{'guest-page': isGuestPage}"
    :style="styles"
  >
    <Component :is="layout">
      <ErrorBoundary>
        <RouterView />
      </ErrorBoundary>
    </Component>
    <UNotification />
  </VApp>
</template>

<script>
import { mapGetters } from 'vuex'

import GuestLayout from '@/layouts/GuestLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import UNotification from '@components/UNotification.vue'
import bgImage from '@/assets/images/bg-account.jpg'
import * as getters from '@/store/getters/types'

export default {
  name: 'App',
  components: {
    UNotification,
    GuestLayout,
    MainLayout,
    EmptyLayout,
    ErrorBoundary: () => import(/* webpackChunkName: "error-boundary" */ '@components/ErrorBoundary.vue')
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR
    }),

    layout () {
      return `${this.$route.meta.layout || 'Empty'}Layout`
    },

    isDevelopment () {
      return process.env.NODE_ENV === 'development'
    },

    isGuestPage () {
      return this.$route.meta.layout === 'Guest'
    },

    styles () {
      const background = this.vendor?.branding?.background
      const defaultImage = this.isDevelopment ? bgImage : '/assets/images/bg-account.jpg'
      const backgroundPath = background ? `url(/${background})` : `url(${defaultImage})`

      return {
        '--app-bar-height': `${this.$vuetify?.application?.top}px`,
        '--app-navigation-width': `${this.$vuetify?.application?.left}px`,
        backgroundImage: this.isGuestPage ? backgroundPath : ''
      }
    }
  },

  watch: {
    isGuestPage: {
      handler: function (val) {
        const root = document.documentElement

        if (val) {
          root.style.setProperty('--app-overflow-y', 'hidden')
        } else {
          root.style.setProperty('--app-overflow-y', 'scroll')
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import './assets/fonts/material-icons/material-icons.css';
@import './assets/fonts/SourceSansPro/index.css';
@import './styles/main.scss';

.v-application.v-application.guest-page {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
