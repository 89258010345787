import store from '@/store/store'
import * as actions from '../store/actions/types'
import { router } from '@/router/router'

class Request {
  _silent = false
  _profileCheck = false
  _options = {
    method: 'GET',
    headers: { Accept: 'application/json' }
  }

  _prepareOptions = (opts) => {
    const options = { ...this._options, ...opts }
    if (options.body && options.body instanceof FormData) {
      const tzOffset = -60 * (new Date()).getTimezoneOffset()
      options.body.append('_tz_offset', tzOffset)
    }

    return options
  }

  _prepareResponse = (response) => {
    if (!this._silent && !this._profileCheck) {
      this._handleErrors(response.status)
    }

    return response.json()
  }

  _showAlerts = (response) => {
    (response.errors || []).forEach(m => {
      store.dispatch(actions.CREATE_NOTIFICATION, {
        type: 'error',
        msg: m
      })
    })

    ;(response.warnings || []).forEach(m => {
      store.dispatch(actions.CREATE_NOTIFICATION, {
        type: 'warning',
        msg: m
      })
    })

    ;(response.info || []).forEach(m => {
      store.dispatch(actions.CREATE_NOTIFICATION, {
        type: 'info',
        msg: m
      })
    })

    ;(response.success || []).forEach(m => {
      store.dispatch(actions.CREATE_NOTIFICATION, {
        type: 'success',
        msg: m
      })
    })
  }

  _handleErrors = (code) => {
    const ERROR_CODE_FORBIDDEN = 403
    const ERROR_CODE_UNAUTHORIZED = 401
    if (code === ERROR_CODE_FORBIDDEN || code === ERROR_CODE_UNAUTHORIZED) {
      setTimeout(() => location.reload(), 10 * 1000)
    }
  }

  _handleResponse = (response) => {
    if (response instanceof Error) {
      response = {
        errors: ['Что-то пошло не так, попробуйте попозже.']
      }
    }

    this._showAlerts(response)

    if (this._profileCheck) {
      if (response.errors && response.errors[0].includes('в своём профиле')) {
        const userID = store.getters?.ACCOUNT?.id
        router.push({ name: 'user-edit', params: { userID }, query: { settings: 'base-information' } }).catch(() => {})
      }
    }

    if (response.errors) {
      throw new Error(response.errors[0])
    }

    return response
  }

  do = (url, opts, silent = false, profileCheck = false) => {
    const options = this._prepareOptions(opts)
    this._silent = silent
    this._profileCheck = profileCheck

    return fetch(url, options)
      .then(this._prepareResponse)
      .catch(this._handleResponse)
      .then(this._handleResponse)
  }
}

export const request = new Request().do
